import * as HttpService from 'services/http.service';
import { getPlannerId } from 'services/identity.service';
import {
  apiResponseParser,
  getPageURL,
  PAGE_NAME
} from 'services/plannerPortal.service';
import { GET_UPCOMING_ASSIGNED_USER_EVENTS_API_URL } from 'services/url.service';

export const getUpcomingAssignedUserEventsAPI = ({
  limit = 10,
  plannerId,
  skip = 0
} = {}) =>
  HttpService.getWithAuth(
    GET_UPCOMING_ASSIGNED_USER_EVENTS_API_URL({
      limit,
      plannerId,
      skip
    })
  );

export const fetchActionCenterDetailsOnPageLoad = async ({
  selectedPlannerId,
  setLoading,
  setShowToast,
  setUpcomingAssignedUserEventsInfo
}) => {
  setLoading(true);

  const result = await apiResponseParser({
    listOfAPI: [
      getUpcomingAssignedUserEventsAPI({
        plannerId: selectedPlannerId
      })
    ],
    setShowToast
  });

  if (result) {
    const [upcomingAssignedUserEventsAndCount] = result;
    setUpcomingAssignedUserEventsInfo(upcomingAssignedUserEventsAndCount);
  }
  setLoading(false);
};

const PLANNER_MANAGERS = JSON.parse(process.env.NEXT_PUBLIC_PLANNER_MANAGERS);

export const isPlannerManager =
  PLANNER_MANAGERS.length === 0 || PLANNER_MANAGERS.includes(getPlannerId());

export const getSelectedPlannerId = ({ plannerId }) =>
  isPlannerManager ? plannerId || '' : getPlannerId();

export const getQueryParameterForScopedUserEvents = ({ selectedPlannerId }) =>
  isPlannerManager && selectedPlannerId ? { plannerId: selectedPlannerId } : {};

export const openTerminationPendingExpiredUserEvents = ({
  selectedPlannerId
}) =>
  window.open(
    getPageURL({
      pageName: PAGE_NAME.EXPIRED_USER_EVENTS.label,
      queryParams: getQueryParameterForScopedUserEvents({
        selectedPlannerId
      })
    }),
    '_blank'
  );
