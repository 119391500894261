import Image from 'next/image';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Select from 'react-select';
import { Col } from 'reactstrap';

import { staticMediaStoreBaseURL } from '@/config/common';

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: '250ms'
  }),
  control: (styles, { isDisabled } = {}) => ({
    ...styles,
    backgroundColor: isDisabled ? '#fbfbfb' : '#fff',
    border: 'none',
    boxShadow: 'none',
    height: '48px',
    '&:hover': {
      border: '1px solid #DC2020',
      cursor: 'pointer'
    }
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? '#FDF1EE' : 'white',
    color: isSelected || '#000'
  })
};

const PlaceholderComponent = ({
  placeholderForGradientBackground,
  placeholderText
}) => (
  <div className='flex gap-2'>
    <Image
      alt='search'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${
        placeholderForGradientBackground ? 'search-button-icon' : 'search_new'
      }.svg`}
      style={{ height: 18, width: 18 }}
      width={0}
    />
    <div className={`${placeholderForGradientBackground ? 'text-white' : ''}`}>
      {placeholderText}
    </div>
  </div>
);

const EnumController = ({
  clearErrors,
  dbName,
  disabled,
  dropdownPlacement,
  isClearable,
  isSearchable,
  options,
  placeholderForGradientBackground,
  placeholderLabel,
  register,
  selectedOption,
  setValue,
  trigger
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const customPlaceholder = isOpen ? (
    <PlaceholderComponent
      {...{
        placeholderForGradientBackground,
        placeholderText: `Search ${placeholderLabel}`
      }}
    />
  ) : (
    <div className={`${placeholderForGradientBackground ? 'text-white' : ''}`}>
      {`Select ${placeholderLabel}`}
    </div>
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Select
      {...{
        ...register(dbName),
        classNamePrefix: 'select2-selection form-select',
        defaultValue: selectedOption,
        isDisabled: disabled,
        onChange: (e) => {
          setValue(dbName, e ? e.value : null);
          trigger();
          clearErrors();
        },
        isClearable,
        isSearchable,
        menuPlacement: dropdownPlacement,
        onMenuClose: toggleMenu,
        onMenuOpen: toggleMenu,
        options,
        placeholder: customPlaceholder,
        styles: customStyles,
        value: selectedOption
      }}
    />
  );
};

const SelectFormInput = ({
  alt = 'dropdown',
  clearErrors,
  dbName,
  disabled = false,
  dropdownPlacement = 'bottom',
  errors,
  getValues,
  icon: { height = 24, url, width = 24 } = {},
  isClearable = true,
  isSearchable = true,
  label,
  labelStyle = 'text-nero text-base font-medium',
  name = '',
  placeholderForGradientBackground = false,
  placeholderLabel = '',
  register,
  selectContainerClassName = 'epm-dropdown border border-platinum rounded',
  setValue,
  showIcon = false,
  showLabel = false,
  trigger = () => {},
  values
}) => {
  const selectedValue = getValues(dbName);
  const options = values.map(({ id, name: labelName }) => ({
    label: labelName,
    value: id
  }));
  const selectedOption = options.find(({ value }) => value === selectedValue);
  const invalidErrorMessage = errors?.[dbName]?.message;

  return (
    <div className='flex flex-col flex-1 gap-2'>
      {showLabel && <label className={labelStyle}>{label}</label>}
      <Col
        className={`relative multi-select-epm h-[50px] ${selectContainerClassName} ${
          invalidErrorMessage ? '!border-brand' : ''
        }`}
      >
        {showIcon && (
          <span className='absolute top-3 left-2 z-[2]'>
            <Image
              {...{
                alt,
                height: 0,
                src: `${staticMediaStoreBaseURL}/icons/${url}`,
                style: { height, width },
                width: 0
              }}
            />
          </span>
        )}
        <EnumController
          {...{
            clearErrors,
            dbName,
            disabled,
            dropdownPlacement,
            isClearable,
            isSearchable,
            name,
            options,
            placeholderForGradientBackground,
            placeholderLabel,
            register,
            selectedOption,
            setValue,
            trigger
          }}
        />
      </Col>
      {invalidErrorMessage && (
        <div className='mt-1 w-full text-red-500 text-xs font-medium invalid-feedback'>
          {invalidErrorMessage}
        </div>
      )}
    </div>
  );
};

export default SelectFormInput;

SelectFormInput.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  dbName: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  name: PropTypes.string,
  placeholderLabel: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  values: PropTypes.array
};
