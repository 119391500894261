import { excludeCharFromString } from '@/lib/utils';
import * as HttpService from '@/services/http.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import {
  ADD_HOST_API_URL,
  GET_HOST_LIST_API_URL,
  GET_HOST_SEGMENTS_API_URL
} from '@/services/url.service';

const getHostListAPI = ({ filters = {}, limit, skip }) =>
  HttpService.getWithAuth(
    GET_HOST_LIST_API_URL({ filters: JSON.stringify(filters), limit, skip })
  );

const addHostAPI = (data) => HttpService.postWithAuth(ADD_HOST_API_URL(), data);

export const getHostSegmentListAPI = () =>
  HttpService.getWithAuth(GET_HOST_SEGMENTS_API_URL());

const defaultFilterParameters = {
  caseSensitive: false,
  comparator: 'LIKE',
  filterType: 'TEXT',
  filterVal: ''
};

const fetchHostByMobile = async ({ mobile }) => {
  const mobileNumber = excludeCharFromString({ string: mobile, char: '+' });
  const { status, entity } = await getHostListAPI({
    filters: {
      mobile: {
        ...defaultFilterParameters,
        filterVal: mobileNumber
      }
    },
    limit: 1,
    skip: 0
  });
  if (status) {
    const { rows } = entity;
    const [matchingHost] = rows || [];
    return matchingHost;
  }
  return null;
};

export const fetchHostSegmentList = async ({
  setHostSegmentList,
  setShowToast
}) => {
  const { entity, message, status } = await getHostSegmentListAPI();
  if (status) {
    setHostSegmentList(entity);
  } else {
    setShowToast({ message, show: true, successToast: status });
  }
};

export const onSubmitCreateNewHostHandler = async ({
  dataToSubmit,
  hostIdWithMatchingMobile,
  router,
  setHostIdWithMatchingMobile,
  setOpenAddHostModal,
  setShowToast
}) => {
  const { codeId, customerName, hostSegmentId, phoneNumber } = dataToSubmit;
  const mobile = `${codeId.code}${phoneNumber}`;
  if (hostIdWithMatchingMobile) return;
  const matchingHost = await fetchHostByMobile({ mobile });
  if (matchingHost?.id) {
    setHostIdWithMatchingMobile(matchingHost.id);
    return;
  }
  const { message, status, entity } = await addHostAPI({
    consentForWhatsapp: true,
    hostSegmentId,
    email: `haflacustomer${mobile}@dummy.com`,
    mobile,
    name: customerName,
    socialProfiles: {}
  });

  setShowToast({ message, show: true, successToast: status });
  if (status) {
    router.push(
      getPageURL({
        pageName: PAGE_NAME.HOST_EVENT_LIST.label,
        pathParams: {
          userId: entity.id
        }
      })
    );
  }
  setOpenAddHostModal(false);
};
