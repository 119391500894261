import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const CloseIcon = ({
  className = 'flex justify-end cursor-pointer',
  height = '20px',
  icon = 'close-with-border-gray.svg',
  iconClassName,
  onClick = () => {},
  width = '20px',
  ...rest
}) => (
  <span
    className={className}
    onClick={onClick}
  >
    <Image
      {...{
        alt: 'cross',
        className: `cursor-pointer ${iconClassName}`,
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/${icon}`,
        style: { height, width },
        width: 0,
        ...rest
      }}
    />
  </span>
);

export default CloseIcon;

CloseIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
