import { PropTypes } from 'prop-types';

const Text = ({ content, HtmlTag = 'div', className = '', ...rest }) => (
  <HtmlTag {...{ className, ...rest }}>{content}</HtmlTag>
);

export default Text;

Text.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  HtmlTag: PropTypes.string,
  className: PropTypes.string
};
