import { useRouter } from 'next/router';

import { CloseIcon, TextKeyValuePair } from '@/components/atomic/nuclei';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ViewAllUserEventsPageHeader = ({ count, title }) => {
  const router = useRouter();
  return (
    <div className='sticky bg-white top-0 h-16 pt-2 z-50 px-8 mt-0'>
      <CloseIcon
        {...{
          className: 'absolute right-4 top-4',
          height: 32,
          icon: 'close-red-icon.svg',
          onClick: () =>
            router.push(
              getPageURL({
                pageName: PAGE_NAME.ACTION_CENTER.label
              })
            ),
          width: 32
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex-row items-center gap-2',
          label: title,
          labelClass: 'text-3xl font-semibold text-dim-gray',
          value: count,
          valueClassName:
            'bg-brand-gradient text-white rounded-full min-w-6 px-1 h-6 text-sm font-medium items-center justify-center flex'
        }}
      />
    </div>
  );
};

export default ViewAllUserEventsPageHeader;
