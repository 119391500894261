import { Text, TextWithIcon } from '@/components/atomic/nuclei';

const PaymentPendingExpiredUserEventCard = () => (
  <div
    className={`flex gap-3 border border-neutral hover:border-brand w-[440px] shadow-navigate-cart rounded-lg p-3 bg-event-container bg-no-repeat bg-right-top`}
  >
    <Text
      {...{
        className:
          'bg-[#F26E271F] text-brand font-semibold w-[64px] h-[64px] rounded flex text-center items-center text-sm',
        content: '25th Aug'
      }}
    />
    <div className='flex flex-col gap-3 flex-1 self-center'>
      <Text
        {...{
          className: 'font-medium text-xs',
          content: `Lakmini Wickramaarachchi Festive Celebration
Reply`
        }}
      />
      <div className='flex justify-between gap-4'>
        <div className='flex gap-4'>
          <TextWithIcon
            {...{
              alt: 'order brand icon',
              className: 'shadow-none',
              icon: 'order-brand-icon.svg',
              iconHeight: 16,
              iconWidth: 16,
              label: '# 1233',
              labelStyle: 'text-xs font-medium text-dim-gray'
            }}
          />
          <TextWithIcon
            {...{
              alt: 'user event icon',
              className: 'shadow-none',
              icon: 'user-event-brand-icon.svg',
              iconHeight: 16,
              iconWidth: 16,
              label: '# 1233',
              labelStyle: 'text-xs font-medium text-dim-gray'
            }}
          />
        </div>
        <TextWithIcon
          {...{
            alt: 'price icon',
            className: 'shadow-none',
            icon: 'price.svg',
            iconHeight: 16,
            iconWidth: 16,
            label: 'AED 1233',
            labelStyle: 'text-xs font-medium text-dim-gray'
          }}
        />
      </div>
    </div>
  </div>
);

export default PaymentPendingExpiredUserEventCard;
