import { useStats } from 'react-instantsearch';

const NoResultsBoundary = ({ children, fallback }) => {
  const { nbHits } = useStats();

  if (nbHits === 0) {
    return fallback;
  }
  return children;
};

export default NoResultsBoundary;
