import Link from 'next/link';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { USER_EVENT_STAGE } from '@/config/common';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ViewAllTerminationPendingExpiredUserEventCard = ({
  hit: {
    entityId,
    eventDate,
    stage,
    userEventName,
    userEventNumber,
    zendeskTicketNumber
  },
  dateStyle = 'w-[64px] h-[64px]',
  gap = 'gap-3'
}) => {
  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    },
    {
      target: `zendesk-ticket-number-${entityId}`,
      text: 'Zendesk Ticket Number'
    },
    {
      target: `event-number-${entityId}`,
      text: 'Event Number'
    },
    {
      target: `stage-${entityId}`,
      text: 'Event Stage'
    }
  ];
  const terminationPendingExpiredUserEventCardDetails = [
    {
      alt: 'zendesk icon',
      icon: 'zendesk-icon.svg',
      id: `zendesk-ticket-number-${entityId}`,
      label: parseNumberedLabel(zendeskTicketNumber)
    },
    {
      alt: 'user event brand icon',
      icon: 'user-event-brand-icon.svg',
      id: `event-number-${entityId}`,
      label: parseNumberedLabel(userEventNumber)
    }
  ];
  return (
    <Link
      className='w-full ml-[17rem]'
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: entityId }
      })}
      target='_blank'
    >
      <div className='flex gap-3 border border-neutral hover:border-brand bg-white shadow-navigate-cart rounded-lg p-3'>
        <Text
          {...{
            className: `bg-[#F26E271F] text-brand font-semibold px-2 rounded flex text-center items-center ${dateStyle}`,
            content: getFormattedDate({ date: eventDate, format: 'Do MMM' }),
            id: `event-date-${entityId}`
          }}
        />
        <div className={`flex flex-col flex-1 ${gap}`}>
          <TextKeyValuePair
            {...{
              className: 'flex justify-between items-center',
              label: userEventName,
              labelClass: 'font-medium text-sm',
              labelId: `event-title-${entityId}`,
              spaceTop: '',
              value: USER_EVENT_STAGE[stage].label,
              valueClassName: `text-xs font-medium rounded-lg py-1 px-2 ${USER_EVENT_STAGE[stage].class}`,
              valueId: `stage-${entityId}`
            }}
          />
          <div className='flex gap-4'>
            {terminationPendingExpiredUserEventCardDetails.map(
              (terminationPendingExpiredUserEventCardDetail, index) => (
                <TextWithIcon
                  key={`${terminationPendingExpiredUserEventCardDetail.id} _${index}`}
                  {...{
                    ...terminationPendingExpiredUserEventCardDetail,
                    labelStyle: 'text-xs font-medium text-dim-gray',
                    iconHeight: 16,
                    iconWidth: 16
                  }}
                />
              )
            )}
          </div>
        </div>
        <Tooltip {...{ configList: tooltipConfigList }} />
      </div>
    </Link>
  );
};

export default ViewAllTerminationPendingExpiredUserEventCard;
